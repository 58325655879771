import { css } from '@emotion/react';
import { Box, Stack } from '@mui/material';
import { Types } from '@scorenco/core';

import { Markdown, Wrapper } from '../../primitives';
import { StrapiImage } from '../../strapi';

type ImageWithTextSliceProps = {
  slice: Types.ComponentSlicesImageWithText;
};

export const ImageWithTextSlice = ({ slice }: ImageWithTextSliceProps) => {
  return (
    <Wrapper marginY={3}>
      <Stack
        direction={{
          xs: 'column',
          md:
            slice.imagePosition === ('gauche' as const) ? 'row' : 'row-reverse',
        }}
        marginX="auto"
        alignItems="center"
        justifyContent="center"
        gap={{
          xs: 3,
          md: 6,
        }}
      >
        <Box
          width={{
            xs: '100%',
            md: slice.imageSize === ('petite' as const) ? '30%' : '50%',
          }}
        >
          <StrapiImage
            css={css`
              object-fit: contain;
              max-height: 38rem;
            `}
            image={slice.image}
          />
        </Box>
        <Box
          width={{
            xs: '100%',
            md: slice.imageSize === ('petite' as const) ? '70%' : '50%',
          }}
        >
          <Markdown value={slice.text} />
        </Box>
      </Stack>
    </Wrapper>
  );
};
