export type OptimizedImageProps = JSX.IntrinsicElements['img'] & {
  alt?: string | null;
  width: number;
  height: number;
};

/**
 * Tag image de base où l'on indique la largeur et la hauteur de l'image.
 * A utiliser quand c'est possible et que l'on souhaite améliorer les perfs SEO.
 */
export const OptimizedImage = ({
  src,
  alt = '',
  loading = 'lazy',
  width,
  height,
  ...rest
}: OptimizedImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      loading={loading}
      width={width}
      height={height}
      {...rest}
    />
  );
};
