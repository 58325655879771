import { css } from '@emotion/react';
import { Types } from '@scorenco/core';

import { OptimizedImage } from '../../primitives';

type StrapiImageProps = {
  image?: Types.UploadFileEntityResponse;
};

export const StrapiImage = ({ image, ...rest }: StrapiImageProps) => {
  const imageInfo = image?.data?.attributes;

  if (!imageInfo) {
    return null;
  }

  return (
    <OptimizedImage
      css={css`
        display: block;
        width: 100%;
        height: auto;
      `}
      src={imageInfo.url}
      width={imageInfo.width as number}
      height={imageInfo.height as number}
      alt={imageInfo.alternativeText}
      {...rest}
    />
  );
};
